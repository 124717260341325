.container {
  margin: 0 50px;
}

.searchBar {
  margin-bottom: 20px;
}

.rightMargin {
  margin-right: 10px;
}
